import {
  useParams,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { selectCourse, selectCourseError } from '../../redux/CourseSlice';
import { fetchCourseById } from '../../redux/CourseSlice/actionCreators';
import { getEntryModuleId } from './CourseModuleRedirect.helper';

export const CourseModuleRedirect: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isModuleError, setIsModuleError] = useState<boolean>(false);

  const { courseId } = useParams<{
    courseId: string;
  }>();

  const course = useSelector(selectCourse);
  const courseError = useSelector(selectCourseError);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchCourseById(courseId));
    }
  }, [courseId, dispatch]);

  useEffect(() => {
    if (!course) {
      // Course has not been fetched yet, wait
      return;
    }
    if (courseId !== course._id) {
      // Course not updated in redux yet, wait
      return;
    }

    const moduleId = getEntryModuleId(course);
    if (moduleId) {
      navigate(`/courses/${course._id}/modules/${moduleId}`, {
        state: location.state,
      });
    } else {
      setIsModuleError(true);
    }
  }, [course]);

  if (!courseId || courseError || isModuleError) {
    return <Navigate to="/404" replace />;
  }

  return <div data-testid="course-page" />;
};
