import { useEffect, useMemo, useRef, useState } from 'react';
import {
  CourseStatus,
  CourseTag,
  CourseType,
  UserCourseResult,
} from '../interfaces';
import { getCourses } from '../api/academy-api/courses';
import { useSearchParams } from 'react-router-dom';
import { PAGINATION_LIMIT__COURSES } from '../../config/consts';
import { ICoursePaginationRequest } from '../interfaces/academy-api/icoursePaginationRequest';
import { CourseStatusCounts } from '../interfaces/academy-api/icoursePaginationResponse';
import { Constants } from '@mono/common';
import { pageToOffset } from '../frontendUtils';
import { CourseFilter } from '../../molecules/CourseFilterDisplay';

export const defaultPage = '1';
export const defaultSearchText = '';
export const defaultCourseFilter: CourseFilter = {
  statuses: [CourseStatus.Todo, CourseStatus.Ongoing],
  tags: [],
  isPremium: false,
};

export interface CourseCount {
  status: CourseStatusCounts | undefined;
  tag: { [key in CourseTag]: number };
  premium: number;
}

type CoursesData = {
  /** @note 'undefined' until loaded, then array of 'UserCourseResult' of the user, with length >= 0 */
  courses: UserCourseResult[] | undefined;
  highlightedCourse?: UserCourseResult | null;
  courseStatusCounts: CourseStatusCounts | undefined;
  paginationMeta?: Awaited<ReturnType<typeof getCourses>>['meta'];
  coursePremiumCount: number;
  userCourses: number;
  adminCourses: number;
};

const initialCoursesData: CoursesData = {
  courses: undefined,
  highlightedCourse: undefined,
  courseStatusCounts: undefined,
  paginationMeta: undefined,
  coursePremiumCount: 0,
  userCourses: 0,
  adminCourses: 0,
};

export const useFetchCourses = (
  filter: CourseFilter | undefined = defaultCourseFilter,
  page: string | undefined = defaultPage,
  search?: string,
) => {
  const setSearchParams = useSearchParams()[1];
  const [coursesData, setCoursesData] =
    useState<CoursesData>(initialCoursesData);
  const [hasAcademyAccess, setHasAcademyAccess] = useState<boolean>(true);
  const [requestParams, setRequestParams] = useState<ICoursePaginationRequest>({
    courseStatus: filter.statuses,
    tags: filter.tags,
    type: filter.isPremium ? CourseType.Premium : undefined,
    limit: PAGINATION_LIMIT__COURSES,
    offset: pageToOffset(PAGINATION_LIMIT__COURSES, page),
    search,
  });
  const prevData = useRef({ search: requestParams.search });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any | null>(null);

  const stableFilter = useMemo(() => filter, [JSON.stringify(filter)]);

  useEffect(() => {
    const pageIndex = Number(page) - 1;

    if (pageIndex < 0) {
      setPage(1, true);
      patchSearchParams({
        filter: defaultCourseFilter,
      });
      return;
    }

    const offset = pageToOffset(PAGINATION_LIMIT__COURSES, page);
    setRequestParams({
      courseStatus: stableFilter.statuses,
      tags: stableFilter.tags,
      type: stableFilter.isPremium ? CourseType.Premium : undefined,
      limit: PAGINATION_LIMIT__COURSES,
      offset,
      search,
    });
  }, [stableFilter, page, search]);

  useEffect(() => {
    const fetchCourses = async () => {
      if (coursesData?.courses /* Including length 0 */) {
        setCoursesData({
          ...coursesData,
          courses: undefined,
          courseStatusCounts:
            requestParams.search !== prevData.current.search
              ? undefined
              : coursesData.courseStatusCounts,
        });
      }

      try {
        if (process.env.NODE_ENV === 'development') {
          await new Promise((r) => setTimeout(r, 500));
        }
        const response = await getCourses(requestParams);
        setCoursesData({
          courses: response.data,
          highlightedCourse: response.highlightedCourse ?? null,
          courseStatusCounts: response.courseStatusCounts,
          paginationMeta: response.meta,
          coursePremiumCount: response.coursePremiumCount,
          userCourses: response.userCourses,
          adminCourses: response.adminCourses,
        });
        setHasAcademyAccess(true);
      } catch (err: any) {
        if (
          err?.response?.data?.code !==
          Constants.ResponseCode.ACADEMY_ACCESS_DENIED
        ) {
          setError(err);
        }
        console.warn('Academy access denied'); // In case user doesn't have access to the academy, use only initialCoursesData
        setHasAcademyAccess(false);
        setCoursesData({
          courses: [],
          highlightedCourse: null,
          courseStatusCounts: {
            [CourseStatus.Completed]: 0,
            [CourseStatus.Ongoing]: 0,
            [CourseStatus.Todo]: 0,
          },
          paginationMeta: undefined,
          coursePremiumCount: 0,
          userCourses: 0,
          adminCourses: 0,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
    prevData.current.search = requestParams.search;
  }, [
    requestParams.courseStatus,
    requestParams.tags,
    requestParams.type,
    requestParams.offset,
    requestParams.search,
  ]);

  useEffect(() => {
    if (coursesData.paginationMeta) {
      const pageIndex = Number(page) - 1;
      const minPossiblePageIndex = 0;
      const maxPossiblePageIndex = Math.max(
        coursesData.paginationMeta.totalPages - 1,
        0,
      );
      if (
        pageIndex > maxPossiblePageIndex ||
        pageIndex < minPossiblePageIndex
      ) {
        setPage(1);
        return;
      }
    }
  }, [coursesData]);

  const patchSearchParams = ({
    filter,
    page,
    search,
  }: {
    filter?: CourseFilter;
    page?: number;
    search?: string;
  }) => {
    let _searchParams = `page=${page || 1}`;

    if (filter) {
      if (filter.statuses.length) {
        _searchParams += `&course_status=${filter.statuses?.join(',')}`;
      }
      if (filter.isPremium) {
        _searchParams += `&type=${CourseType.Premium}`;
      }
      if (filter.tags.length) {
        _searchParams += `&tags=${filter.tags.join(',')}`;
      }
    } else {
      if (requestParams.courseStatus?.length) {
        _searchParams += `&course_status=${requestParams.courseStatus?.join(',')}`;
      }
      if (requestParams.type) {
        _searchParams += `&type=${requestParams.type}`;
      }
      if (requestParams.tags?.length) {
        _searchParams += `&tags=${requestParams.tags.join(',')}`;
      }
    }

    const _search = search ?? requestParams.search;
    if (_search) {
      _searchParams += `&search=${_search}`;
    }

    setSearchParams(_searchParams);
  };

  const setPage = (nextPage: number, _force?: boolean) => {
    if (!_force) {
      if (!coursesData.paginationMeta || typeof nextPage !== 'number') return;
      if (coursesData.paginationMeta.currentPage === nextPage) return;
    }

    patchSearchParams({ page: nextPage });
  };

  const setSearch = (search: string) => {
    patchSearchParams({ search });
  };

  const setFilter = (filter: CourseFilter) => {
    patchSearchParams({ filter });
  };

  const courseCount: CourseCount = {
    status: coursesData.courseStatusCounts,
    tag: {
      User: coursesData.userCourses,
      Admin: coursesData.adminCourses,
    },
    premium: coursesData.coursePremiumCount,
  };

  return {
    courses: coursesData.courses,
    highlightedCourse: coursesData.highlightedCourse,
    courseCount,
    paginationMeta: coursesData.paginationMeta,
    loading,
    error,
    requestParams,
    setPage,
    setSearch,
    setFilter,
    hasAcademyAccess,
  };
};
