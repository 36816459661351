import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StyledClass from './LinkTo.module.css';

interface LinkProps {
  text: string;
  textKey?: string;
  type?: 'regular' | 'email';
  to: string;
  fontSize?: 'h2' | 'body';
}

export const LinkTo = (props: LinkProps) => {
  const { text, textKey, type = 'regular', to, fontSize = 'body' } = props;
  const { t } = useTranslation();
  return (
    <span
      className={`${StyledClass.container} ${StyledClass[type]} ${StyledClass[fontSize]}`}
    >
      <Link
        to={to}
        onClick={(e) => {
          if (type !== 'email') {
            return;
          }
          window.location.href = `mailto:${text}`;
          e.preventDefault();
        }}
      >
        {textKey ? t(textKey, text) : text}
      </Link>
    </span>
  );
};
